import React, { useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import { GatsbyImage } from "gatsby-plugin-image";
import { Button } from "react-bootstrap";
import { Link } from "gatsby";

const SupportCard = ({ article }) => {
	return (
		<div>
			<GatsbyImage
				className="w-100"
				style={{ height: "200px" }}
				image={
					article.supportFields.image.localFile.childImageSharp.gatsbyImageData
				}
				alt={article.supportFields.image.altText}
			/>
			<p className="text-uppercase mt-3">{article.supportFields.date}</p>
			<h2 className="text-dark-bg fs-5">{article.title}</h2>
			<p>{article.supportFields.description}</p>
			<Button
				as={Link}
				to={`/support-articles/${article.slug}`}
				className="px-5 me-md-4 w-100 w-md-auto text-white mt-2"
				variant="secondary"
			>
				READ
			</Button>
		</div>
	);
};

export default SupportCard;
