import { useStaticQuery, graphql, Link } from "gatsby";
import React, { useContext, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../components/layout";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import Hero from "../components/hero";
import ReviewCardProf from "../components/review-card-prof";
import ReviewCard from "../components/review-card";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import SupportCard from "../components/support-card";
import gsap from "gsap";
import { Button } from "react-bootstrap";
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image";
import { useAuth0 } from "@auth0/auth0-react";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import Card from "react-bootstrap/Card";

const ResourcesPage = () => {
	const data = useStaticQuery(graphql`
		query {
			heroImg: wpMediaItem(title: { eq: "What-to-Expect-Hero-Image" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}

			allWpResource(sort: { fields: date, order: DESC }) {
				nodes {
					slug
					title

					resourceFields {
						description
						link
						image {
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: [AUTO, WEBP]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: CONTAIN }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
							altText
						}
					}
				}
			}

			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);
	const { user } = useAuth0();
	const siteUrl = data.site.siteMetadata.siteUrl;

	const enterAnimation = (btn) => {
		gsap.to(
			btn,

			{ opacity: 1, duration: 0.5 }
		);
	};
	const exitAnimation = (btn) => {
		gsap.to(btn, { opacity: 0, duration: 0.5 });
	};

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "Resources",
				item: {
					url: `${siteUrl}/resources`,
					id: `${siteUrl}/resources`,
				},
			},
		],
	};

	return (
		<>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>

			<GatsbySeo
				title="Resources | The Facial Rehabilitation Centre"
				description=""
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/resources`,
					title: "Resources | The Facial Rehabilitation Centre",
					description: "",
					images: [
						{
							url: `${data.heroImg?.localFile.publicURL}`,
							width: `${data.heroImg?.localFile.childImageSharp.original.width}`,
							height: `${data.heroImg?.localFile.childImageSharp.original.height}`,
							alt: `${data.heroImg?.altText}`,
						},
					],
				}}
			/>

			<Layout>
				<section>
					<Hero
						subTitle="Home > Resources"
						title="Resources"
						backgroundImage={
							data.heroImg?.localFile.childImageSharp.gatsbyImageData
						}
					/>
				</section>
				<section className="py-5 py-lg-7">
					<Container>
						<Row className="g-6 ">
							{data.allWpResource.nodes.map((charity) => (
								<Col className="" md={6} lg={4}>
									{/* <div
										onMouseEnter={() => enterAnimation(`.${charity.slug}`)}
										onMouseLeave={() => exitAnimation(`.${charity.slug}`)}
										className="w-100 position-relative "
										style={{
											overflow: "hidden",
										}}
									>
										<div
											style={{
												opacity: 0,
												zIndex: 3,
												background:
													"transparent linear-gradient(180deg, rgba(60, 104, 175, 0.69) 0%, rgba(60, 104, 175, 0.69) 100%) 0% 0% no-repeat padding-box",
											}}
											className={`position-absolute text-center d-flex align-items-center justify-content-center ${charity.slug} w-100 h-100 top-0 start-0`}
										>
											<div className="d-block px-4 px-lf-6">
												<h2 className="text-white fs-3">{charity.title}</h2>

												<Button
													target="_blank"
													rel="noreferrer"
													href={charity.resourceFields.link}
													className="px-3  w-100 w-md-auto text-white mt-1"
													variant="secondary"
												>
													{" "}
													Download File
												</Button>
											</div>
										</div>
										<GatsbyImage
											style={{ height: "250px", objectPosition: "top" }}
											image={
												charity.resourceFields.image.localFile.childImageSharp
													.gatsbyImageData
											}
											alt={charity.resourceFields.image.altText}
										/>
									</div> */}
									<Card>
										<Card.Img
											as={GatsbyImage}
											variant="top"
											image={
												charity.resourceFields.image.localFile.childImageSharp
													.gatsbyImageData
											}
											alt={charity.resourceFields.image.altText}
										/>
										<Card.Body>
											<Card.Title className="text-secondary">
												{charity.title}
											</Card.Title>
											<Card.Text>
												{charity.resourceFields.description}
											</Card.Text>
											<Button
												target="_blank"
												rel="noreferrer"
												href={charity.resourceFields.link}
												className="px-3  w-100 w-md-auto text-white mt-1"
												variant="primary"
											>
												{" "}
												Download File
											</Button>
										</Card.Body>
									</Card>
								</Col>
							))}
						</Row>
					</Container>
				</section>
			</Layout>
		</>
	);
};

export default withAuthenticationRequired(ResourcesPage);
